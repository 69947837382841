<template>
    <div>
        <CCard class="w-100">
            <CCardHeader>
                <h2>{{FormType}} Request : {{RequestCode}}</h2>
            </CCardHeader>
            <CCardBody>
                <div class="px-3">
                    <CRow>
                        <div class="col-sm-label">
                            <label class="form-label font-weight-bold">Nomor Request</label>
                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                        </div>
                        <div class="col-sm-content">
                            <CInput id="RequestCode" v-model="RequestCode" class="font-weight-bold" />
                            <label id="errorRequestCode" class="form-error pb-2" style="display: none; color: red;"></label>
                        </div> 

                        <div class="col-sm-label">
                            <label class="form-label font-weight-bold">Tanggal</label>
                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                        </div>
                        <div class="col-sm-content">
                            <date-picker
                                v-model="RequestDate"
                                format="DD MMM YYYY"
                                type="date"
                                placeholder="Select date"
                                value-type="YYYY-MM-DD"
                                style="width:100%"
                            ></date-picker>
                            <label id="errorRequestDate" class="form-error pb-2" style="display: none; color: red;"></label>
                        </div> 

                        <div class="col-sm-label">
                            <label class="form-label font-weight-bold">Status</label>
                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                        </div>
                        <div class="col-sm-content">
                            <CSelect id="Status" class="font-weight-bold" :options="StatusData" :value.sync="Status" />
                            <label id="errorStatus" class="form-error" style="display: none; color: red;"></label>
                        </div> 
                    </CRow>
                    <CRow>
                        <div class="col-sm-label">
                            <label class="form-label font-weight-bold required">Alamat</label>
                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                        </div>
                        <div class="col-sm-content">
                            <textarea class="form-control" rows="2" v-model="Address"></textarea>
                            <label id="errorAddress" class="form-error pb-2" style="display: none; color: red;"></label>
                        </div> 

                        <div class="col-sm-label">
                            <label class="form-label font-weight-bold">Note</label>
                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                        </div>
                        <div class="col-sm-content">
                            <textarea class="form-control" rows="2" v-model="Note"></textarea>
                            <label id="errorNote" class="form-error pb-2" style="display: none; color: red;"></label>
                        </div> 
                        
                        <div class="col-sm-label">
                            <label class="form-label font-weight-bold">Note Print</label>
                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                        </div>
                        <div class="col-sm-content">
                            <textarea class="form-control" rows="2" v-model="NotePrint"></textarea>
                            <label id="errorNotePrint" class="form-error pb-2" style="display: none; color: red;"></label>
                        </div> 
                    </CRow>
                    <br>
                    <CRow>
                        <div class="col-sm-label">
                            <label class="form-label font-weight-bold">Attachment</label>
                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                        </div>
                        <div class="col-sm-content">
                             <div class="row">
                                <div class="col-8 p-0 pl-3">
                                    <div class="form-control">
                                        <div v-if="this.FileStatus == 'Uploaded'">
                                            <a href="#" @click="fileClick()" class="label-file" id="uploadedFile"><label>{{File}}</label></a>
                                        </div>
                                        <div v-if="this.FileStatus == 'New'">
                                            <label class="label-file">{{File}}</label>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-2 p-0 px-2">
                                    <label for="File" class="form-control" style="text-align: center;"><i class="fa fa-upload"></i></label>
                                    <input type="file" id="File" @input="onChangeFile()" style="display:none"/>
                                </div>
                                <div class="col-2 p-0 pr-3">
                                    <CButton type="button" @click="deleteAttachmentClick()" id="deleteAttachmentButton" color="danger" style="width:100%"> <i class="fa fa-trash"></i> </CButton>
                                </div>
                                
                            </div>
                            <label id="errorFile" class="form-error" style="display: none; color: red;"></label>
                        </div> 
                    </CRow>
                    <br>
                    <CRow class="p-3" >
                        <div class="p-3 border rounded" style="width: 100%;">
                            <label class="form-label font-weight-bold" style="margin-bottom: 0px"><h4>Item</h4></label>
                            <button class="btn btn-primary float-right"                     
                                @click="addItemClick()">
                                <i class="fa fa-plus"></i> Add
                            </button>
                            <hr>

                            <datasource ref="itemDataSource" :data="this.ItemGridData" :page-size="10" :schema-model-fields="this.ItemSchemaModel"/>

                            <kendo-grid ref="gridItem"
                                        :data-source-ref="'itemDataSource'"
                                        :editable="true"
                                        :filterable="true"
                                        :sortable="true"
                                        :pageable="true"
                                        :cellClose="itemCellClose"
                                        :resizable="true"
                                        >
                                <kendo-grid-column  :field="'product_code'"
                                                    :title="'Kode'"
                                                    :width="200"
                                                    :headerAttributes="{ style: 'text-align:center; font-weight:bold;' }"></kendo-grid-column>

                                <kendo-grid-column  :field="'item_name'"
                                                    :title="'Nama'"
                                                    :width="200"
                                                    :headerAttributes="{ style: 'text-align:center; font-weight:bold;' }"></kendo-grid-column>

                                <kendo-grid-column  :field="'uom'"
                                                    :title="'Satuan'"
                                                    :width="200"
                                                    :headerAttributes="{ style: 'text-align:center; font-weight:bold;' }"></kendo-grid-column>

                                <kendo-grid-column  :field="'quantity'"
                                                    :title="'Qty'"
                                                    :width="200"
                                                    :headerAttributes="{ style: 'text-align:center; font-weight:bold;' }"
                                                    :attributes="{style:'text-align:right'}"></kendo-grid-column>
                                                    
                                <kendo-grid-column  :title="'&nbsp;'"
                                                    :attributes="{ class: 'k-text-center' }"
                                                    :command="['destroy']"
                                                    :width= 200></kendo-grid-column>
                            </kendo-grid>

                            <item-form ref="itemForm" :saveItemGrid="saveItemGrid"/>
                        </div>
                    </CRow>
                    <CButton type="button" @click="saveClick()" class="save-button" id="saveButton" color="success" style="float: right;"> Save </CButton>
                    <label style="float: right;">&nbsp;&nbsp;</label>
                    <CButton type="button" color="secondary" style="float: right;" data-bs-dismiss="modal" aria-label="Close" @click="$router.push('/request')"> Close </CButton>
                </div>
            </CCardBody>
        </CCard>
        <file-viewer ref="fileViewer"/>
    </div>
</template>

<script>
import datePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import requestServices from '../Script/RequestServices';
import { Grid, GridColumn} from '@progress/kendo-grid-vue-wrapper';
import { DataSource } from '@progress/kendo-datasource-vue-wrapper';
import { apiBaseUrl } from '../../../../infrastructure/constant/connection'
import FileViewer from '../../../../shared/FileViewer.vue';
import { globalfunc } from '../../../../shared/GlobalFunction.js';
import moment from 'moment';
import { response } from '../../../../infrastructure/constant/response';

//Form
import ItemForm from './ItemForm.vue';

export default {
    name: 'RequestFormPage',
    components: {
        'date-picker' : datePicker,
        'item-form' : ItemForm,
        'kendo-grid' : Grid,
        'kendo-grid-column': GridColumn,
        'datasource' : DataSource,
        'file-viewer': FileViewer,
    },
    async mounted () {
        if(this.FormType == 'Add'){

            this.RequestCode = 'Auto';
            var dateNow = moment(new Date()).format("YYYY-MM-DD");
            this.RequestDate = dateNow;
            this.StatusData = [ {value: 'New', label: 'New'} ];
            this.Status = 'New';

            //File
            this.File = '';
            this.FileStatus = 'New';

            this.Address = '';
            this.Note = '';
            this.NotePrint = '';

            this.SaveType = 'Add';
        }
        else {
            var reqData = await requestServices.getRequestData(this.RequestId);
            
            this.RequestCode = reqData.request_number;
            this.RequestDate = reqData.request_date;
            this.StatusData = await this.$globalfunc.globalDropdown('ddl_status_purchasing_request');
            this.Status = reqData.status;

            //File
            this.File = reqData.attachment;
            this.FileStatus = 'Uploaded';

            this.Address = reqData.address;
            this.Note = reqData.notes;
            this.NotePrint = reqData.printed_notes;
            
            //grid
            this.ItemGridData = this.$globalfunc.objectToArrayConverter(reqData.purchasing_request_detail, "Request-ItemGridDataPurchasingPO");

            this.SaveType = 'Edit';

            document.getElementById('RequestCode').readOnly = true;

            if(this.View == 'true'){
                $(".save-button").hide();
            }
        }
    },
    data(){
        return{
            FormType: this.$route.params.formtype,
            View: this.$route.params.view,
            Error : 0,

            //Detail
            RequestId: this.$route.params.id,
            RequestCode: '',
            RequestDate: '',
            Status: '',
            StatusData: [],

            File: '',
            FileStatus: '',

            Address: '',
            Note: '',
            NotePrint: '',
           
            // grid
            ItemGridData: [],
            ItemSchemaModel: {
                item_id: { type: "string", editable: false },
                product_code: { type: "string", editable: false },
                item_name: { type: "string", editable: false },
                uom: { type: "string", editable: false },
                quantity: { type: "number", editable: true, validation: { min:1 } },
            },
        }
    },
    methods: {
        onChangeFile(){
            var file = document.getElementById('File').files[0];
            
            this.FileStatus = 'New';
            this.File = file.name;
        },
        fileClick(){
            this.$refs.fileViewer.viewClick(this.File);
        },
        addItemClick(){
            this.$refs.itemForm.addClick();
        },
        saveItemGrid(data){
            var gridItem = this.$refs.gridItem.kendoWidget().dataSource._data;
            var itemData = this.$globalfunc.objectToArrayConverter(gridItem, "Request-ItemGridDataPurchasingPO");
            
            var duplicate = false;
            if(data != null){
                var duplicate = requestServices.duplicateItemChecker(gridItem, data);
            }

            if(duplicate){
                this.$swal("Error", "Item sudah ada", "error");
            } else {
                itemData = itemData.concat(data);
                this.ItemGridData = itemData;
            }
        },
        itemCellClose(e){
            var dataItem = e.sender.dataItem($(e.container).parent());
            if(dataItem.qty == null){
                dataItem.qty = 0;
            }
        },
        inputValidation(){
            this.Error = 0;
            $(".form-error").css("display", "none");

            //Validation
            if(this.RequestCode == '' || this.RequestCode == null){
                this.errorShow('errorRequestCode');
            }
            if(this.RequestDate == '' || this.RequestDate == null){
                this.errorShow('errorRequestDate');
            }
            if(this.Address == ''){
                this.errorShow('errorAddress');
            }
            if(this.ItemGridData.length < 1){
                this.$swal("Error", "Item tidak boleh kosong ", "error");
                this.Error++;
            }
        },
        async saveClick(){
            this.inputValidation();

            var file = document.getElementById('File').files[0];
            if(file == undefined)(
                file = null
            )

            //Proses
            if(this.Error == 0){
                var gridItem = this.$refs.gridItem.kendoWidget().dataSource._data;

                if (this.SaveType == 'Add'){
                    this.$loading(true);
                    var itemData = this.$globalfunc.objectToArrayConverter(gridItem, "Request-ItemGridDataRequest");

                    const requestData = {
                        request_number: this.RequestCode,
                        status: this.Status,
                        request_date: this.RequestDate,
                        notes: this.Note,
                        printed_notes: this.NotePrint,
                        address: this.Address,
                        attachment: file,
                        purchasing_request_detail: itemData
                    }

                    const variables = {
                        data : requestData
                    }
                    
                    requestServices.addQuery(variables).then(res => {
                        this.$loading(false);
                        this.$swal("Info", response.successSave, "success").then(
                            this.$router.push({ path: '/request' })
                        );
                    }).catch(error => {
                        this.$loading(false);
                        var errorMessage = globalfunc.errorExtractor(error)
                        this.$swal("Error", errorMessage, "error");
                    })
                }
                else if (this.SaveType == 'Edit') {
                    this.$loading(true);
                    var itemData = this.$globalfunc.objectToArrayConverter(gridItem, "Request-ItemGridDataRequest");

                    const requestData = {
                        request_number: this.RequestCode,
                        status: this.Status,
                        request_date: this.RequestDate,
                        notes: this.Note,
                        printed_notes: this.NotePrint,
                        address: this.Address,
                        attachment: file,
                        purchasing_request_detail: itemData
                    }

                    const variables = {
                        id : parseInt(this.RequestId),
                        data : requestData
                    }
                    
                    requestServices.editQuery(variables).then(res => {
                        this.$loading(false);
                        this.$swal("Info", response.successUpdate, "success").then(
                            this.$router.push({ path: '/request' })
                        );
                    }).catch(error => {
                        this.$loading(false);
                        var errorMessage = globalfunc.errorExtractor(error)
                        this.$swal("Error", errorMessage, "error");
                    })
                }
            }
        },
        errorShow(errorCode){
            document.getElementById(errorCode).style.display = 'block';
            document.getElementById(errorCode).innerHTML = response.fieldRequired;
            if(this.Error <= this.$globalvariable.max_error_focus){ document.getElementById(errorCode.replace("error", "")).focus(); }
            this.Error++;
        },
        deleteAttachmentClick(){
            $('#File').val('');
            this.FileStatus = '';
            this.File = '';
        }
    }
}
</script>

<style scoped>
.label-file {
  width : 100%;
  overflow:hidden;
  display:inline-block;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>