<template>
    <div>
        <div class="modal fade" id="itemProjectModal" tabindex="-1" aria-labelledby="itemProjectModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-xl-custom modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header">
                        <h4 id="itemProjectModalLabel" class="font-weight-bold">Add Item</h4>
                        <a type="button" data-bs-dismiss="modal" aria-label="Close">
                            <i class="fa fa-times"></i>
                        </a>
                    </div>

                    <div class="modal-body">
                        <kendo-grid ref="grid"
                            :data-source="dataSource"
                            :filterable="filterableConfig"
                            :sortable="true"
                            :pageable="pageableConfig"
                            :columns="columns"
                            :selectable="true"
                            :editable="true"
                            :resizable="true"
                            >
                        </kendo-grid>
                        <br/>

                        <CButton type="button" @click="saveClick()" id="saveButton" color="success" style="float: right;"> Save </CButton>
                        <label style="float: right;">&nbsp;&nbsp;</label>
                        <CButton type="button" color="secondary" style="float: right;" data-bs-dismiss="modal" aria-label="Close"> Close </CButton>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { Grid, GridColumn } from '@progress/kendo-grid-vue-wrapper';
import { apiBaseUrl } from '../../../../infrastructure/constant/connection'
import requestServices from '../Script/RequestServices';
import {globalfunc} from '../../../../shared/GlobalFunction';
import { kendo_grid } from '../../../../infrastructure/constant/variable';

export default {
    name: 'ItemProjectForm',
    components: {
        'kendo-grid' : Grid,
        'kendo-grid-column': GridColumn,
    },
    props: ['saveItemGrid'],
    mounted:  function () {
    },
    data: function () {
        return {
            dataSource: [],
            columns:  [
                { field: "product_code", title: "Kode", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                { field: "item_name", title: "Nama", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                { field: "barcode", title: "Bar Code", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" } }, 
                { field: "item_type", title: "Item Type", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" } }, 
                { field: "type_detail_id", title: "Tipe Detail", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                { field: "base_uom", title: "Satuan", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" } }, 
                { field: "base_weight", title: "Nilai Satuan", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes:{style: "text-align:right;"} }, 
            ],
            ItemSchemaModel: {
                item_id: { editable: false},
                product_code: { editable: false},
                item_name: { editable: false},
                barcode: { editable: false},
                item_type: { editable: false},
                type_detail_id: { editable: false},
                base_uom: { editable: false},
                base_weight: { editable: false},
                qty: {type: "number", editable: true}
            },
            filterableConfig:  {
                extra: true,
                operators: kendo_grid.default_operator
            },
            pageableConfig: kendo_grid.default_pageable_config
        }
    },
    methods: {
        addClick(){ 
            var itemSchemaModel = this.ItemSchemaModel;

            this.dataSource = new kendo.data.DataSource ({
                page: 1,
                pageSize: kendo_grid.default_grid_pagesize,
                serverPaging: true,
                serverFiltering: true,
                serverSorting: true,
                transport: {
                    read: {
                        contentType: "application/json",
                        url: apiBaseUrl,
                        type: "POST",
                        data: function(e) {
                            const paging = {
                                Page : {Limit: e.pageSize,Page: e.page},
                                Filter : globalfunc.filterQueryBuilder(e.filter),
                                OrderBy : globalfunc.sortingQueryBuilder(e.sort)
                            }
                            const variables = {
                                paging : paging
                            }
                            return { query: requestServices.readItemQuery(),
                                        variables : variables}
                        },
                        beforeSend: function (req) {
                            req.setRequestHeader('Authorization', window.$cookies.get('Token'));
                            req.setRequestHeader('Path', window.location.pathname);
                        }
                    },
                    parameterMap: function(options, operation) {
                        return  kendo.stringify({
                            query: options.query,
                            variables: options.variables
                        });
                    },
                    cache: false,
                },
                schema: {
                    data: function (response) {
                        if(response.data.GetProductMaster.ProductMaster == null){
                            return [];
                        }else{
                            return response.data.GetProductMaster.ProductMaster;
                        }
                    },
                    total: function (response) {
                        if(response.data.GetProductMaster.ProductMaster == null){
                            return 0;
                        }else{
                            return response.data.GetProductMaster.Total;
                        }
                    },
                    model: {
                        fields: itemSchemaModel
                    }
                },
                sort: { field: "product_code", dir: "asc" }
            });

            window.$('#itemProjectModal').modal('show');
        },
        saveClick(){
            var grid = this.$refs.grid.kendoWidget();
            var selectedItem = grid.dataItem(grid.select());
            var errorMessage = "";

            if(selectedItem != null){
                var obj = {
                    item_id: selectedItem.item_id,
                    product_code: selectedItem.product_code,
                    item_name: selectedItem.item_name,
                    uom: selectedItem.base_uom,
                    quantity: 1
                }
                this.$props.saveItemGrid(obj);
            }else{
                errorMessage = "Dimohon untuk memilih item terlebih dahulu";
                this.$swal("Error", errorMessage, "error");
            }
        }
    }
}
</script>
<style scoped>
.modal-xl-custom {
    max-width: 1500px;
}
</style>